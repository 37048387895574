@import '../partials/variables';

@mixin tablet {  
    @media (min-width: $tablet-breakpoint) {
      @content;
    }
  }

@mixin desktop {  
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin header-link {
  padding: .5rem 1rem;
  margin: .25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  @include tablet {
      margin: 0 1rem;
      padding: 1rem 2rem;
      font-size: 1.5rem;
  } &:hover {
      @include button-hover-1;
  }
}

@mixin flex-column{
  display: flex;
  flex-direction: column;
}

@mixin button-hover-1 {
  cursor: none;
  border-radius: 40px;
  box-shadow: inset 20rem 0 0 0 $dark-fill-color;
  color: $text-color-light-hover;
}

@mixin button-hover-2 {
  transform: scale(1.01);
  box-shadow: 0 0 5px $light-fill-color;
  border-radius: 1px;
  border: none;
}

@mixin form-border {
  border: 4px solid black;
  border-radius: 40px;
  background-color: transparent;
  border-bottom: 4px solid; 
}

@mixin light-theme {
  color: #{$text-color-light};
  background-color: $background-color-light;}

@mixin dark-theme {
  color: #{$text-color-dark};
  background-color: $background-color-dark;
}
