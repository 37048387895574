@import '../../styles/partials/_variables';
@import '../../styles/partials/_mixins';

.light-theme .header__theme-icon,
.light-theme .header__toggle-menu {
    fill: $dark-fill-color; 
    color: $dark-fill-color;
}

.dark-theme .header__theme-icon,
.dark-theme .header__toggle-menu {
    fill: $light-fill-color; 
    color: $light-fill-color;
}

.header {
    font-family: $comfortaa-font;
    @include flex-column;
    align-items: center;
    justify-content: space-between;      
    backdrop-filter: blur(10px);
        @include tablet {
            flex-direction: row;
            height: 100px;
            position: sticky;
            top: 0;
            z-index: 10;
        }

    &__toggle-menu {
        border: none;
        border-radius: 1000px;
        @include header-link;
            @include desktop {
                display: none;
            }
    }

    &__nav-1 {
        display: contents;
        @include desktop {
            display: none;
        }
    }

    &__nav-2 {
        display: none;
            @include desktop {
                display: contents;
            }
    }

    &__nav-section {
        display: flex;
        align-items: center;
    }

    &__info {
        @include flex-column;
        align-items: center;
        @include tablet {
            flex-direction: row;
            align-items: baseline;
        }
    }

    &__title {
        font-size: 2rem;
        margin: 1rem;
            @include tablet {
                font-size: 2rem;
                    @include desktop {
                        font-size: 4rem;
                    }
            }
    }

    &__nav-list {
       @include flex-column;
        padding: 0;
        @include tablet {
            flex-direction: row;
        }
    }

    &__nav-item {
       @include header-link;
    }

    &__button {
        background-color: transparent;
        border: none;
    }

    &__theme-icon  {
        height: 20px;
        width: 20px;
        margin: 1rem;
            @include tablet {
                height: 60px;
                width: 60px;
                    @include desktop {
                        height: 80px;
                        width: 80px
                    }
            }
    }
}

