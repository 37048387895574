@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.light-theme .profile__background {
    background-color: $light-fill-color; 
    color: $background-color-dark; 
}
  
.dark-theme .profile__background {
    background-color: $dark-fill-color; 
    color: $background-color-light;
}

.profile {
    font-family: $roboto-font;
    display: flex;
    flex-direction: column;
    align-items: center;
        @include tablet {
            align-items: flex-start;
        }

    &__name-title {
        font-family: $roboto-font;
        font-size: 1rem;
            @include tablet {
                font-size: 2rem;
                    @include desktop {
                        flex-direction: row;
                    }
            }
    }

    &__background {
        margin: 0;
        padding: 2rem 1rem;
            @include tablet {
                padding: 4rem 2rem;
            }
    }

    &__title-background {
        font-size: 1.25rem;
        margin: 6rem 0 0 1rem;
            @include tablet{
                font-size: 2rem;
            }
    }

    &__img-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem;
            @include tablet {
                margin: 4rem;
            }
    }

    &__img {
       height: 200px;
        @include tablet {
            height: 300px;
                @include desktop {
                    height: 400px;
                }   
        }
         
    }

    &__info {
        font-family: $comfortaa-font;
        margin: 2rem;
        line-height: 2rem;
            @include tablet {
                font-size: 1.5rem;
                line-height: 3rem;
                margin: 3rem;
            }
    }
}

