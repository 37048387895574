@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.container {
  max-width: 50vw;
    @include tablet {
      margin-left: 8rem;
      max-width: 80vw;
        @include desktop {
          max-width: 80vw;
        }
    }
}

.animate-charcter {
    background-image: linear-gradient(
    -225deg,
    #100c0d 0%,
    #013328 29%,
    #cc8b65 67%,
    #e3dcd2 100%
  );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 4s linear infinite;
    display: inline-block;
    font-size: 2rem;
    text-align: center;
        @include tablet {
            font-size: 5rem;
            text-align: left;
              @include desktop {
                font-size: 4rem;
                margin: 2rem;
              }
        }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}