@import '../../styles/partials/_variables';
@import '../../styles/partials/_mixins';

.projects {
    font-family: $roboto-font;
        @include tablet {
            flex-direction: row;
            margin: 2rem;
                @include desktop {
                    margin: 8rem 2rem;
                }
        }

    &__blurb {
        @include tablet {
            font-size: 1.5rem;
            margin: 4rem;
            max-width: 70vw;
            
        }
    }

    &__list {
        @include flex-column;
        align-items: center;
        margin: 2rem;
            @include tablet {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
    }
 
    &__list-items {
        padding: 2rem;
        margin: 1rem;
        max-width: 20rem;
        border: 1px solid;
        width: 200px;
            @include tablet {
                height: 450px;
                 width: 350px;
            } &:hover {
                @include button-hover-2;
            }
    }

    &__name {
        margin: 0;
    }

    &__tech-stack {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    } 

    &__tech-stack-item {
        border: 2px solid;
        padding: .5rem;
        margin: .5rem;
        font-size: .75rem;
        border-radius: 30px;
            @include tablet {
                padding: 1rem;
                margin: .5rem;
                font-size: 1rem;
            } 

    }
}