@import '../../styles/partials/_variables';
@import '../../styles/partials/_mixins';

.light-theme .work__header {
    border: 2px solid $light-fill-color; 
    color: $background-color-dark; 
}
  
.dark-theme .work__header { 
    border: 2px solid $dark-fill-color;
    color: $background-color-light;
}

.work {
    margin: 3rem 0;
        @include tablet {
            margin: 4rem 0;
        }

    &__button-section {
        @include flex-column;
        justify-content: flex-start;
            @include tablet {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
    }

    &__button {
        font-family: $roboto-font;
        border: 2px solid $dark-fill-color;
        border-radius: 40px;
        padding: .75rem;
        margin: 1rem;
        text-decoration: none;
        text-align: center;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
            @include tablet {
                font-size: 1.5rem;
                padding: 1rem;
            }&:hover {
                @include button-hover-1;
        }
    }

    &__main-section {
        margin: 1.25rem;
        text-wrap: wrap;
    }

    &__header { 
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: .5rem 0;
        padding: 0 1rem;
        height: 50px;
        border-radius: 40px;
    }

    &__dropdown-icon {
        fill: white;
        height: 50px;
        width: 50px;
    }

    &__details-list,
    &__list {
        padding: 0;
        margin: 2rem 0;
    }

    &__education,
    &__experience,
    &__skills {
        font-size: 1rem;
        margin: 1rem;
            @include tablet {
                font-size: 2rem;
            }

    }
    
    &__list-item {
        font-family: $roboto-font;
        margin: 1rem;
            @include tablet {
                padding: 0;
                margin: 2rem 1rem;
                font-size: 1.5rem;
            }
    }
    
    &__job,
    &__company,
    &__duration,
    &__description {
        font: 1rem;
            @include tablet {
                font-size: 1.5rem;
            }
    }

    &__job {
        font-weight: bold;
    }

    &__duration {
        font-size: .75rem;
            @include tablet {
                font-size: 1rem;
            }
    }

    &__description {
        font-size: 1rem;
        padding: 0 0 0 1rem;
        margin: .5rem;
    }
}
