@font-face {
    font-family: 'Comfortaa';
    src: url('../../assets/fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf')
    format('woff2');
}

@font-face {
    font-family: 'Roboto'; 
    src: url(../../assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf)
    format('woff2');
}

