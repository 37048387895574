@import '../../styles/partials/_variables';
@import '../../styles/partials/_mixins';

.light-theme .contact {
    background-color: $light-fill-color; 
    color: $background-color-dark; 
}
  
.dark-theme .contact {
    background-color: $dark-fill-color; 
    color: $background-color-light;
}

.contact {
    padding: 2rem;
        @include tablet {
            padding: 2rem;
            flex-direction: row;
            justify-content: space-evenly;
                @include desktop {
                    padding: 4rem; 
                }
        }

    &__title {
        font-family: $roboto-font;
        margin: 0;
    }

    &__main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
            @include desktop {
                flex-direction: row;
            }
    }

    &__thank-you {
        font-family: $comfortaa-font;
        margin: 1rem;
        text-align: center;
            @include tablet {
                padding: 1rem 4rem;
                margin: 0;
                font-size: 1.5rem;
                max-width: 35rem;
            }

        }

    &__img {
        width: 250px;
        margin: 1rem;
            @include tablet {
                width: 350px;       
            }
        }

    &__name,
    &__email {
        margin: 1rem;
        padding: 1rem;
        @include form-border;
            @include tablet {
                font-size: 1.5rem;
                padding: 2rem
            }
    }

    &__message {
        margin: 1rem;
        padding: 1rem;
        overflow: auto;
        @include form-border;
            @include tablet {
                font-size: 1.5rem;
                padding: 2rem;
            }
        }

    &__button {
        border-radius: 1000px;
        padding: 1rem;
        margin: 1rem;
        border: none;
    }

    &__connect-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__connect {
        font-family: $roboto-font;
        margin: 3rem 0;
            @include tablet {
                margin: 4rem;
            }
        }
        
        &__form-div {
            display: flex;
            flex-direction: column;
            align-items: center;
                @include desktop {
                    align-items: flex-start;
                }
        }
    
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem;
            @include tablet {
                align-items: flex-start;
                margin: 4rem;
            }
        }
}

input[type="text"]::placeholder,
input[name="email"]::placeholder,
textarea::placeholder {
    color: white;
   
}


